<template>
<div class="return-policy">
  <div
      class="contact-img"
      :style="bgImg"
  >
    <h2
        class="title"
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-delay="50"
        data-aos-duration="500"
    >Support Center</h2>
  </div>
  <div class="return-policy-block">
    <div class="contact-container">
      <h2 class="section-title">RETURN POLICY</h2>
      <svg width="130" height="130" viewBox="0 0 130 130" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M48.75 55.626L65 65.5182V83.8908L48.75 73.9986V55.626ZM48.75 52.5791C48.2279 52.5807 47.7145 52.7134 47.257 52.965C46.7849 53.2304 46.392 53.6169 46.1189 54.0846C45.8458 54.5523 45.7023 55.0844 45.7031 55.626V73.9986C45.7036 74.521 45.8383 75.0345 46.0944 75.4899C46.3505 75.9452 46.7194 76.327 47.1656 76.5986L63.4156 86.4908C63.8927 86.7823 64.4409 86.9369 65 86.9377C65.5234 86.9361 66.0376 86.7997 66.493 86.5416C66.9636 86.2771 67.3555 85.8922 67.6285 85.4264C67.9016 84.9607 68.0459 84.4307 68.0469 83.8908V65.5182C68.0378 65.0044 67.899 64.5013 67.6433 64.0556C67.3876 63.6099 67.0233 63.2361 66.5844 62.9689L50.3344 53.0768C49.8625 52.7674 49.314 52.5951 48.75 52.5791Z" fill="#3A3A3A"/>
        <path d="M81.25 55.626V73.9986L65 83.8908V65.5182L81.25 55.626ZM81.25 52.5791C80.6909 52.5799 80.1427 52.7345 79.6656 53.026L63.4156 62.9689C62.9767 63.2361 62.6124 63.6099 62.3567 64.0556C62.101 64.5013 61.9622 65.0044 61.9531 65.5182V83.8908C61.9541 84.4307 62.0984 84.9607 62.3715 85.4264C62.6445 85.8922 63.0364 86.2771 63.507 86.5416C63.9624 86.7997 64.4766 86.9361 65 86.9377C65.5591 86.9369 66.1073 86.7823 66.5844 86.4908L82.8344 76.5986C83.2806 76.327 83.6495 75.9452 83.9056 75.4899C84.1617 75.0345 84.2964 74.521 84.2969 73.9986V55.626C84.2977 55.0844 84.1542 54.5523 83.8811 54.0846C83.608 53.6169 83.2151 53.2304 82.743 52.965C82.2855 52.7134 81.7721 52.5807 81.25 52.5791Z" fill="#3A3A3A"/>
        <path d="M63.9234 46.7187L79.8078 54.6609L65.457 63.7305L49.207 54.6609L63.9234 46.7187ZM63.9234 43.6719C63.4199 43.6727 62.9244 43.7983 62.4812 44.0375L47.7344 51.9797C47.2543 52.2376 46.8521 52.6196 46.5699 53.0859C46.2876 53.5521 46.1356 54.0855 46.1297 54.6305C46.1283 55.1824 46.2769 55.7242 46.5595 56.1983C46.8422 56.6723 47.2482 57.0606 47.7344 57.3219L63.9844 66.3914C64.462 66.6581 65.0026 66.7913 65.5494 66.777C66.0963 66.7627 66.6292 66.6014 67.0922 66.3102L81.443 57.2305C81.8997 56.9421 82.2715 56.5376 82.5204 56.0582C82.7693 55.5789 82.8863 55.042 82.8594 54.5026C82.8326 53.9631 82.6628 53.4405 82.3675 52.9883C82.0722 52.536 81.662 52.1704 81.1789 51.9289L65.2844 43.9969C64.8621 43.7844 64.3961 43.6732 63.9234 43.6719Z" fill="#3A3A3A"/>
        <path d="M28.5797 40.8994C34.3632 32.1591 43.0971 25.7902 53.1869 22.9552C63.2767 20.1203 74.0496 21.0084 83.5389 25.4574C93.0283 29.9065 100.601 37.6197 104.875 47.1892C109.149 56.7586 109.84 67.5459 106.82 77.582C103.8 87.6181 97.272 96.2336 88.427 101.856C79.582 107.478 69.0101 109.732 58.6413 108.206C48.2724 106.68 38.7981 101.476 31.9479 93.5436C25.0977 85.6117 21.3284 75.4807 21.3281 65.0002" stroke="#3A3A3A" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M39.8937 38.8272L30.225 38.3804L23.8266 31.1187L22.7195 54.021L39.8937 38.8272Z" fill="#3A3A3A"/>
      </svg>
      <p class="text-item">
        CE North America, LLC, warrants your new product to be free from defects in materials and workmanship under
        normal household use for a period of two (2) year from the date of purchase. During this period, such defects
        will be repaired or the product replaced at CE’s option. I f the product or component is no longer available, we
        will replace with a similar product of equal or greater value. This warranty does not cover glass , filters wear
        from normal use, not in conformity with the printed directions, or damage to the product resulting from
        accident, alteration, abuse, or misuse. This warranty extends only to the original consumer purchaser or gift
        recipient. Keep the original sales receipt, as proof of purchase is required to make a warranty claim. This
        warranty is void if the product is used for other than single-family household use or subjected to any voltage
        and waveform other than as specified on the rating label (e.g., 120V — 60 Hz).
      </p>
    </div>
  </div>
  <Footer/>
</div>
</template>

<script>
import Footer from "@/components/Nav/Footer";
export default {
  name: "ReturnPolicy",
  components: {Footer},
  data(){
    return{
      bgImg: {
        backgroundImage: `url(${require('@/assets/images/img/support-bg.png')})`
      }
    }
  },
  mounted() {
    window.scrollTo(0,0)
  },
}
</script>

<style lang="scss">
@import "@/assets/scss/_vars.scss";
.return-policy{
  background-color: $white;
  .contact-img{
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: top center;
    padding: 414px 0 77px 100px;
    .title{
      color: $white;
    }
  }
  .return-policy-block{
    .contact-container{
      padding: 42px 0 70px;
      text-align: center;
    }
  }
}
</style>